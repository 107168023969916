import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { Typography } from '@material-ui/core'
import IndexLayout from './IndexLayout'

class TagRoute extends React.Component {
  render() {
    const posts = (this.props as any).data.allMarkdownRemark.edges
    const postLinks = posts.map((post: any) => (
      <li key={post.node.fields.slug}>
        <Link to={post.node.fields.slug}>
          <Typography variant="h2">{post.node.frontmatter.title}</Typography>
        </Link>
      </li>
    ))
    const { tag } = (this.props as any).pageContext
    const { title } = (this.props as any).data.site.siteMetadata
    const { totalCount } = (this.props as any).data.allMarkdownRemark
    const tagHeader = `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with “${tag}”`

    return (
      <IndexLayout>
        <section className="section">
          <Helmet title={`${tag} | ${title}`} />
          <div className="container content">
            <div className="columns">
              <div className="column is-10 is-offset-1" style={{ marginBottom: '6rem' }}>
                <Typography variant="h3">{tagHeader}</Typography>
                <ul className="taglist">{postLinks}</ul>
                <p>
                  <Link to="/tags/">Browse all tags</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </IndexLayout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000, sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { tags: { in: [$tag] } } }) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
